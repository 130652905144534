<template>
  <div class="picker">
      <span @click="picker">
          {{ input||text }}
        <i class="iconfont icondown-arrow"></i>
      </span>
  </div>
</template>

<script>
import mixins from "@/mixins/form";
export default {
  props: ["text", "value", "model","options"],
  data() {
    return {
      input: "",
    };
  },
  mounted() {
    this.input = this.value;
  },
  mixins: [mixins],
  methods: {
    picker() {
      this.$weui.picker(
        this.options,
        {
          className: "custom-classname",
          container: "body",
          defaultValue: [5],
          onChange: function (result) {
           
          },
          onConfirm:  (result)=>{
            this.input = result[0].label
            this.$emit("update:model", result[0].value);
            this.$emit("change",result[0].value)
          },
          id: "singleLinePicker",
        }
      );
    },
  },
};
</script>

<style >
.picker{
  text-align: center;
}
.picker .iconfont{
    font-size: .12rem;
}
</style>