export default {
    methods:{
        isWebView(){
            if(this.$route.query.token){
                console.log(this.$route.query.token,'token')
                let {token,mobile,detail,position} = this.$route.query
                this.form.mobile = mobile
                this.$store.commit("setUserInfo", {
                    mobile: mobile,
                    detail: detail?detail:{},
                    token: token,
                    position:position
                  });
            }  else{
                return
            } 
        }
    }
};
