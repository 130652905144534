export default {
  methods: {
    nowTime(time) {
      let date = !time?new Date():new Date(time);
      let y = date.getFullYear();
      let m = this.format(date.getMonth() + 1);
      let d = this.format(date.getDate());
      return `${y}-${m}-${d} 00:00:00`;
    },
    format(num) {
      return num < 10 ? "0" + num : num;
    },
  },
};
