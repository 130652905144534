<template>
  <div class="picker">
    <span @click="picker">
      {{ input || text }}
      <i class="iconfont icondown-arrow"></i>
    </span>
  </div>
  <!-- <div class="weui-cell">
    <div class="weui-cell__bd">{{ text }}</div>
    <div class="weui-cell__ft">
      <span @click="picker" :class="!input ? 'placeholder' : ''">{{
        input || placeholder
      }}</span>
    </div>
  </div> -->
</template>

<script>
import mixins from "@/mixins/form";
export default {
  props: ["placeholder", "text", "value", "model", "options"],
  data() {
    return {
      input: "",
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    model: function () {
      this.init();
      //   this.input = this.model;
    },
  },
  methods: {
    picker() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      let d = date.getDate();
      if (!this.defaultValue) {
        this.defaultValue = [y, m, d];
      }
      this.$weui.datePicker({
        start: 2000, // 从今天开始
        end: 2100,
        defaultValue: this.defaultValue,
        onChange: function (result) {},
        onConfirm: (result) => {
          this.input = result[0].label + result[1].label + result[2].label;
          this.$emit(
            "update:model",
            `${result[0].value}-${this.format(result[1].value)}-${this.format(
              result[2].value
            )} 00:00:00`
          );
          this.$emit('change',`${result[0].value}-${this.format(result[1].value)}-${this.format(
              result[2].value
            )} 00:00:00`)
        },
        id: "picker" + Math.random() * 10000000,
      });
    },
    format(num) {
      return num < 10 ? "0" + num : num;
    },
    init() {
      if (this.model) {
        let timeStr = this.model.substr(0, 10);
        let arr = timeStr.split("-");
        this.input =
          arr[0] +
          "年" +
          (arr[1].indexOf("0") == 0 ? arr[1].substr(1, 1) : arr[1]) +
          "月" +
          (arr[2].indexOf("0") == 0 ? arr[2].substr(1, 1) : arr[2]) +
          "日";
        this.defaultValue = [
          arr[0],
          arr[1].indexOf("0") == 0 ? arr[1].substr(1, 1) : arr[1],
          arr[2].indexOf("0") == 0 ? arr[2].substr(1, 1) : arr[2],
        ];
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.picker{
  text-align: center;
}
</style>