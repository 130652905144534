export default {
    watch:{
        model(){
            this.input = this.model;
        },
        options(){
            this.input = this.model;
        }
    },
};
