<template>
  <div class="container">
    <div class="tabbar">
      <span :class="{ active: current == 0 }" @click="changeTab(0)"
        >今日风险地区</span
      >
      <span :class="{ active: current == 1 }" @click="changeTab(1)"
        >历史风险地区</span
      >
    </div>
    <legend>
      <span class="level3">高风险</span>
      <span class="level2">中风险</span>
      <span class="level1">低风险</span>
    </legend>
    <div class="search">
      <div class="search-item">
        <el-cascader
          filterable
          size="small"
          :options="options_"
          v-model="selectedOptions"
          @change="changePosition"
          placeholder="区域"
          clearable
          style="width:100%"
          :props="{ checkStrictly: true }"
        ></el-cascader>
      </div>
      <div class="search-item" v-if="current==1">
        <el-date-picker
          v-model="time"
          align="right"
          type="date"
          placeholder="时间"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd hh:mm:ss"
          @change="handleDate"
          size="small"
          clearable
          style="width:100%"
        >
        </el-date-picker>
      </div>
    </div>
    <ul>
      <li
        class="card"
        :class="'level' + item.levelCode"
        v-for="(item, index) in list"
      >
        <h5>
          <span>{{ item.provinceName }}</span>
          <span>{{ item.cityName }}</span>
          <span>{{ item.areaName }}</span>
        </h5>
        <div class="bot">
          <span>
            <!-- <img src="@/assets/position.png" alt="" /> -->
            {{ item.communityName }}
          </span>
        </div>
        <p class="time" v-if="current==1">{{item.createdTime.substr(0,10)}}</p>
        <!-- <p>
          <span class="tag" :class="'level' + item.levelCode">{{
            item.levelCode == 1
              ? "低风险地区"
              : item.levelCode == 2
              ? "中风险地区"
              : "高风险地区"
          }}</span>
        </p> -->
        <!-- <p class="history" @click="getHistory(item.cityCode, index)">
          历史记录 <i class="el-icon-arrow-down"></i>
        </p>
        <section class="histories" v-if="histories[index]">
          <div v-for="child in histories[index]" class="card cardchild">
            <h5>
              {{ child.provinceName }} {{ child.cityName }} {{ child.areaName }}
            </h5>
            <p>{{ child.communityName }}</p>
            <p class="showIcon">{{ child.createdTime.substr(0, 10) }}</p>
            <p>
              <span class="tag" :class="'level' + item.levelCode">{{
                item.levelCode == 1
                  ? "低风险地区"
                  : item.levelCode == 2
                  ? "中风险地区"
                  : "高风险地区"
              }}</span>
            </p>
          </div>
        </section> -->
      </li>
    </ul>
  </div>
</template>

<script>
import apicker from "@/components/common/picker";
import adate from "@/components/common/date";
import { areaList, getRiskHistory } from "@/api/api";
import webview from "@/mixins/webview";
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from "element-china-area-data";
import time from "@/mixins/time";
export default {
  data() {
    return {
      query: {
        pn:1,
        ps: 99999,
        startTime: this.nowTime().substr(0, 10)+ " 00:00:00",
        endTime: this.nowTime().substr(0, 10) + " 23:59:59",
      },
      areas: [],
      list: [],
      tripList: [],
      checked: false,
      organization: [],
      province: [],
      city: [],
      options_: provinceAndCityData,
      selectedOptions: [],
      time: "",
      form: {},
      histories: [],
      current: 0,
    };
  },
  mounted() {
    this.isWebView();
    this.getList();
    let arr = [...provinceAndCityData].map((item) => {
      return {
        label: item.label,
        value: item.label,
      };
    });
    this.province = arr;
  },
  components: {
    apicker,
    adate,
  },
  mixins: [webview, time],
  methods: {
    changeTab(current) {
      this.current = current;
      if (this.current == 0) {
        this.query = {
          ps: 99999,
          startTime: this.nowTime().substr(0, 10)+ " 00:00:00",
          endTime: this.nowTime().substr(0, 10) + " 23:59:59",
        };
        this.selectedOptions = ''
        this.getList()
      } else {
        this.selectedOptions = ''
        this.query = {ps:99999}
        this.getList()
      }
    },
    getList() {
      areaList(this.query).then((res) => {
        this.list = res.data.data.list;
      });
    },

    handleChange(e) {
      this.query.inBeijing = e ? 2 : 0;
      this.getList();
    },
    handlePicker(e) {
      this.query.institution = e;
      this.getList();
    },
    handleDate(e) {
      this.query.startTime =e?e.substr(0, 10) + " 00:00:00":null;
      this.query.endTime = e?e.substr(0, 10) + " 23:59:59":null;
      this.getList();
    },
    changeProvince(e) {
      this.query.provinceName = e.substr(0, e.length - 1);
      this.query.cityName = "";
      let arr = [];
      provinceAndCityData.forEach((item) => {
        if (item.label == e) {
          arr = item.children;
        }
      });
      let list = arr.map((item) => {
        if (item.label == "市辖区") {
        } else {
          return {
            label: item.label,
            value: item.label,
          };
        }
      });
      this.city = list;
      this.getList();
    },
    changeCity(e) {
      if (e === "市辖区") {
        this.query.cityName = "";
      } else {
        this.query.cityName = e.substr(0, e.length - 1);
      }
      this.getList();
    },
    getHistory(code, index) {
      if (this.histories[index]) {
        this.$set(this.histories, index, "");
      } else {
        getRiskHistory({ cityCode: code }).then((res) => {
          // this.histories[index] = res.data.data
          this.$set(this.histories, index, res.data.data);
        });
      }
    },
    changePosition(e) {
      this.query.cityName = null;
      this.query.provinceName = null;
      regionData.forEach((parent) => {
        if (parent.value == e[0]) {
          this.query.provinceName = parent.label.substr(
            0,
            parent.label.length - 1
          );
          parent.children.forEach((item) => {
            if (item.value == e[1]) {
              if (item.label == "市辖区") {
                this.query.cityName = "";
              } else {
                this.query.cityName = item.label.substr(
                  0,
                  item.label.length - 1
                );
              }
            }
          });
        }
      });
      this.getList();
    },
  },
};
</script>

<style scoped>
.container {
  padding-top: 0.6rem;
}
.tabbar {
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  background: #fff;
  z-index: 9999;
}
.tabbar > span {
  width: 50%;
  text-align: center;
  padding: 0.17rem 0 0.12rem;
  color: #999999;
}
.tabbar > span.active {
  color: #082a4d;
  border-bottom: 1px solid #2f7dcd;
  font-weight: bold;
}
legend {
  display: flex;
  padding: 0 0.1rem;
  margin: 0.1rem 0;
  width: 100%;
}
legend > span {
  width: 1rem;
  padding-left: 0.16rem;
  position: relative;
  font-size: 0.14rem;
  margin-right: 0.2rem;
}
legend > span::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #e20000;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
legend > span.level3::before {
  background: #e20000;
}
legend > span.level2::before {
  background: #e6a40f;
}
legend > span.level1::before {
  background: #666666;
}
.level3 {
  color: #e20000;
}
.level2 {
  color: #e6a40f;
}
.level1 {
  color: #666666;
}
ul {
  background: #fff;
  padding: 0 0.15rem;
  list-style: none;
}
ul li {
  border-bottom: 1px solid #ccc;
  padding: 0.1rem 0;
  font-size: 0.14rem;
}
ul li h5 {
  font-weight: normal;
  font-size: 0.14rem;
}

.search{
  display: flex;
  margin-bottom: 0.1rem;
}
.search-item{
  flex: 1;
  padding:0 0.1rem;
}
.time{
  color: #333;
}
/* .card {
  background: #fff;
  padding: 0.1rem 0.15rem 0.12rem;
  border-top: 1px solid #d9d9d9;
  position: relative;
}
.card h5 {
  font-size: 0.16rem;
  font-weight: normal;
}
.card h5 span {
  margin-right: 0.1rem;
}
.card > p {
  font-size: 0.15rem;
  margin: 0.04rem 0;
}
.bot {
  display: flex;
}
.bot img {
  width: 0.16rem;
}
.bot {
  display: flex;
  justify-content: space-between;
  margin-top: 0.05rem;
}
.bot span {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  color: #040404;
}
.bot span:nth-child(2) {
  color: #2f7dcd;
  justify-content: center;
}
.status {
  position: absolute;
  top: 50%;
  right: 0.2rem;
  background: rgba(79, 119, 170, 0.19);
  width: 0.56rem;
  height: 0.28rem;
  line-height: 0.28rem;
  color: #021412;
  font-size: 0.14rem;
  border-radius: 10px;
  text-align: center;
}
.search {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  padding: 0.14rem 0.1rem;
  display: flex;
  z-index: 999;
}
.search-item {
  flex: 1;
  font-size: 0.15rem;
  padding-right: 0rem;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-item:not(:last-child):after {
  content: "";
  height: 0.15rem;
  width: 0px;
  border-right: 1px solid rgba(112, 112, 112, 0.6);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.showIcon {
  flex: 0.3 !important;
  color: #2f7dcd;
}
.hisContent {
  padding: 0.2rem 0;
}
.tag {
  display: inline-block;
  padding: 3px 0.1rem;
  border-radius: 0.15rem;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: #fff;
}
.level2 {
  background: #e8a270;
}
.level1 {
  background: #878786;
}
.level3 {
  background: #e36f6b;
}
.history {
  font-size: 12px !important;
  margin-top: 0.1rem !important;
}
.cardchild {
  margin-bottom: 0 !important;
  border: none !important;
  background: #f5f5f5;
  border-radius: 10px;
} */
</style>